import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { Link, StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import _ from 'lodash'
import { Navigation } from '.'
import config from '../../utils/siteConfig'

// Styles
import '../../styles/ghost/app.css'

/**
* Main layout component
*
* The Layout component wraps around each page and template.
* It also provides the header, footer as well as the main
* styles, and meta data for each page.
*
*/
const DefaultLayout = ({ data, children, bodyClass, isHome }) => {
    // const site = data.allGhostSettings.edges[0].node
    const twitterUrl = config.twitter ? `https://twitter.com/${config.twitter.replace(/^@/, ``)}` : null
    const facebookUrl = config.facebook ? `https://www.facebook.com/${config.facebook.replace(/^\//, ``)}` : null
    let aboutPages = data.aboutPage.edges
    let myAboutPage = _.find(aboutPages, (tempPage) => {
        return (_.some(tempPage.node.authors, (tempAuthor) => {
            if (_.indexOf(config.authors, tempAuthor.slug) >= 0) {
                return true
            } else {
                return false
            }
        }))
    })
    return (
    <>
        <Helmet>
            <html lang={config.lang} />
            <body className={bodyClass} />
        </Helmet>

        <div className="viewport">

            <div className="viewport-top">
                {/* The main header section on top of the screen */}
                <header className="site-head">
                    <div className="container">
                        <div className="site-mast">
                            <div className="site-mast-left">
                                <Link to="/">
                                    { config.logo ?
                                        <img className="site-logo" src={config.logo} alt={config.title} />
                                        : (data.file ?
                                            <Img fixed={data.file.childImageSharp.fixed} alt={config.title} />
                                            : null)
                                    }
                                </Link>
                            </div>
                            <div className="site-mast-right">
                                { config.twitter && <a href={ twitterUrl } className="site-nav-item" target="_blank" rel="noopener noreferrer"><img className="site-nav-icon" src="/images/icons/twitter.svg" alt="Twitter" /></a>}
                                { config.facebook && <a href={ facebookUrl } className="site-nav-item" target="_blank" rel="noopener noreferrer"><img className="site-nav-icon" src="/images/icons/facebook.svg" alt="Facebook" /></a>}
                                <a className="site-nav-item" href={ `https://feedly.com/i/subscription/feed/${config.siteUrl}/rss/` } target="_blank" rel="noopener noreferrer"><img className="site-nav-icon" src="/images/icons/rss.svg" alt="RSS Feed" /></a>
                            </div>
                        </div>
                        { isHome ?
                            <div className="site-banner">
                                <h1 className="site-banner-title">{config.title}</h1>
                                <p className="site-banner-desc">{config.description}</p>
                            </div> :
                            null}
                        <nav className="site-nav">
                            <div className="site-nav-left">
                                {/* The navigation items as setup in Ghost */}
                                <Navigation data={config.navigation} navClass="site-nav-item" />
                            </div>
                            <div className="site-nav-right">
                                <Link className="site-nav-button" to={`/${myAboutPage ? myAboutPage.node.slug : 'about'}/`}>About</Link>
                            </div>
                        </nav>
                    </div>
                </header>

                <main className="site-main">
                    {/* All the main content gets inserted here, index.js, post.js */}
                    {children}
                </main>

            </div>

            <div className="viewport-bottom">
                {/* The footer at the very bottom of the screen */}
                <footer className="site-foot">
                    <div className="site-foot-nav container">
                        <div className="site-foot-nav-left">
                            <Link to="/">{config.title}</Link> © 2020 &mdash; Published with <a className="site-foot-nav-item" href="https://www.xiaoju.ca" target="_blank" rel="noopener noreferrer">XIAOJU Tech</a>
                        </div>
                        <div className="site-foot-nav-right">
                            <Navigation data={config.navigation} navClass="site-foot-nav-item" />
                        </div>
                    </div>
                </footer>

            </div>
        </div>

    </>
    )
}

DefaultLayout.propTypes = {
    children: PropTypes.node.isRequired,
    bodyClass: PropTypes.string,
    isHome: PropTypes.bool,
    data: PropTypes.shape({
        allGhostSettings: PropTypes.object.isRequired,
    }).isRequired,
}

const DefaultLayoutSettingsQuery = props => (
    <StaticQuery
        query={graphql`
            query GhostSettings {
                allGhostSettings {
                    edges {
                        node {
                            ...GhostSettingsFields
                        }
                    }
                }
                file(relativePath: {eq: "icon.png"}) {
                    childImageSharp {
                        fixed(width: 50, height: 50) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
                aboutPage: allGhostPost (sort: {order: DESC, fields: [published_at]}, filter: {tags: {elemMatch: {name: {in: ["about"]}}}}) {
                    edges {
                        node {
                            slug
                            authors {
                                id
                                slug
                                name
                                profile_image
                            }
                        }
                    }
                }
            }
        `}
        render={data => <DefaultLayout data={data} {...props} />}
    />
)

export default DefaultLayoutSettingsQuery
